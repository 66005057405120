import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/bod/modules/document/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import DocumentState from "@/store/bod/modules/document/types/documentState";
import { BodController } from "@/api/bod";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import getObjectsDiff from "@/utils/getObjectsDiff";
import router from "@/router/bod";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import { plainToInstance } from "class-transformer";
import ApiCreateDocumentChangeRequestRequest from "@/api/bod/types/apiCreateDocumentChangeRequestRequest";
import ChangeRequestsListItem from "@/store/bod/modules/requests/types/changeRequestsListItem";
import mapper from "@/store/bod/modules/document/mapper";
import requestsMapper from "@/store/bod/modules/requests/mapper";
import Document from "@/store/bod/modules/document/types/document";
import ApiChangeRequestsListItem from "@/api/bod/types/apiChangeRequestsListItem";
import ApiChangeRequest from "@/api/bod/types/apiChangeRequest";
import { RouteNames } from "@/router/bod/routes";
import ApiGetChangeRequestsParameters from "@/api/bod/types/apiGetChangeRequestsParameters";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiDocument from "@/api/bod/types/apiDocument";
import { cloneDeep } from "lodash";
import ApiCreateDocumentRequest from "@/api/bod/types/apiCreateDocumentRequest";
import { i18n } from "@/plugins";
import ChangeRequest from "@/store/bod/modules/document/types/changeRequest";
import UpdateDocumentRequest from "@/store/bod/modules/document/types/updateDocumentRequest";
import ApiUpdateDocumentRequest from "@/api/bod/types/apiUpdateDocumentRequest";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import { DocumentSnapshotKeys } from "@/store/bod/modules/document/types/documentSnapshotKeys";
import ApiUpdateRequest from "@/api/bod/types/apiUpdateRequest";
import UpdateRequest from "@/store/bod/modules/document/types/updateRequest";
import ApiCreateDocumentUnpublishRequestRequest from "@/api/bod/types/apiCreateDocumentUnpublishRequestRequest";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import { getUserWithUniqueFullName } from "@/store/bod/modules/document/helpers/getUserWithUniqueFullName";
import { refreshRoute } from "@/utils/router";
import ApiCreateRollbackDocumentRequestRequest from "@/api/bod/types/apiCreateRollbackDocumentRequestRequest";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import UserState from "@/store/bod/modules/user/types/userState";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import ApiCreateBodFile from "@/api/bod/types/apiCreateBodFile";
import ApiCreateDocumentRepublishRequestRequest from "@/api/bod/types/apiCreateDocumentRepublishRequest";

const abortService = new AbortService();
const bodController = new BodController(abortService);

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: DocumentSnapshotKeys.DOCUMENT,
			fields: ["document", "editableFile", "nonEditableFile"]
		}),
		new SnapshotOptions({
			key: DocumentSnapshotKeys.REQUEST,
			fields: ["request", "requestEditableFile", "requestNonEditableFile", "changeWarrantFileMeta", "documentChangeWarrantFileMeta"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new DocumentState(
			new ListingModel<ChangeRequestsListItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "createdAt",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<DocumentState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	...listingMixin.getters,
	[getterTypes.users]: state => {
		return state.users.map(x => {
			return plainToInstance(ApiBodUser, getUserWithUniqueFullName(x, state.users));
		});
	},
	[getterTypes.isCreateUnpublishRequestPageWithWeb]: state => {
		return state.document.isWeb && router.currentRoute.name === RouteNames.DOCUMENT_UNPUBLISH;
	}
};

const actions = <ActionTree<DocumentState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, rootState, getters }) {
		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_IS_FORM_LOADING, true);
		await Promise.all([
			dispatch(actionTypes.fetch),
			dispatch(actionTypes.fetchHistory)
		]);
		commit(mutationTypes.SET_IS_FORM_LOADING, false);

		if([RouteNames.DOCUMENT_UPDATE, RouteNames.DOCUMENT_UNPUBLISH].includes(router.currentRoute.name as RouteNames) &&
			state.users.some(x => state.document.responsibleUser.id === x.id))
			commit(mutationTypes.SET_REQUEST_RESPONSIBLE_USER, state.document.responsibleUser);
		if(router.currentRoute.name === RouteNames.DOCUMENT_ROLLBACK) {
			const { user } = resolveNestedState<UserState>(rootState, storeManager.bod.user.namespace);
			commit(mutationTypes.SET_REQUEST_RESPONSIBLE_USER, user);
			commit(mutationTypes.SET_REQUEST_INITIATOR, getUserWithUniqueFullName(user, state.users));
		}

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, DocumentSnapshotKeys.DOCUMENT);
		commit(mutationTypes.SET_STATE_SNAPSHOT, DocumentSnapshotKeys.REQUEST);
	},
	async [actionTypes.fetchHistory]({ commit }) {
		if(router.currentRoute.name !== RouteNames.DOCUMENT)
			return;

		try {
			const { items } = await bodController.getChangeRequests(plainToInstance(ApiGetChangeRequestsParameters,
				{ documentId: router.currentRoute.params.documentId }));

			commit(mutationTypes.SET_LISTING_ITEMS,
				items.map(x => requestsMapper.map(x, ApiChangeRequestsListItem, ChangeRequestsListItem)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
	},
	async [actionTypes.fetch]({ dispatch, commit, state }) {
		try {

			if(router.currentRoute.params.requestId) {
				const [data, requestPermissions] = await Promise.all([
					bodController.getRequest(router.currentRoute.params.requestId),
					bodController.getRequestPermissions(router.currentRoute.params.requestId)
				]);

				const request = mapper.map(data, ApiChangeRequest, ChangeRequest);

				const documentPermissions = await bodController.getDocumentPermissions(request.document.id);

				if(request.editableFile)
					commit(mutationTypes.SET_REQUEST_EDITABLE_FILE, request.editableFile);

				if(request.nonEditableFile)
					commit(mutationTypes.SET_REQUEST_NON_EDITABLE_FILE, request.nonEditableFile);

				if(request.changeWarrantFile)
					commit(mutationTypes.SET_CHANGE_WARRANT_FILE, request.changeWarrantFile);

				if(request.document.editableFile)
					commit(mutationTypes.SET_DOCUMENT_EDITABLE_FILE, request.document.editableFile);

				if(request.document.nonEditableFile)
					commit(mutationTypes.SET_DOCUMENT_NON_EDITABLE_FILE, request.document.nonEditableFile);

				commit(mutationTypes.SET_REQUEST, request);
				commit(mutationTypes.SET_DOCUMENT, request.document);
				commit(mutationTypes.SET_SAVED_DOCUMENT_IS_WEB, request.document.isWeb);
				commit(mutationTypes.SET_REQUEST_PERMISSIONS, requestPermissions);
				commit(mutationTypes.SET_DOCUMENT_PERMISSIONS, documentPermissions);
			}

			if(router.currentRoute.params.documentId) {
				const requests = [
					bodController.getDocument(router.currentRoute.params.documentId),
					bodController.getDocumentPermissions(router.currentRoute.params.documentId)
				];

				if([RouteNames.DOCUMENT_UPDATE, RouteNames.DOCUMENT_UNPUBLISH].includes(router.currentRoute.name as RouteNames))
					requests.push(dispatch(actionTypes.fetchUsers));

				const [document, permissions] = await Promise.all(requests);

				commit(mutationTypes.SET_DOCUMENT, mapper.map(document, ApiDocument, Document));
				commit(mutationTypes.SET_SAVED_DOCUMENT_IS_WEB, (document as ApiDocument).isWeb);
				commit(mutationTypes.SET_DOCUMENT_PERMISSIONS, permissions);


				if((document as ApiDocument).editableFile)
					commit(mutationTypes.SET_DOCUMENT_EDITABLE_FILE, (document as ApiDocument).editableFile);

				if((document as ApiDocument).nonEditableFile)
					commit(mutationTypes.SET_DOCUMENT_NON_EDITABLE_FILE, (document as ApiDocument).nonEditableFile);
			}
		} catch (error) {
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	},
	async [actionTypes.createNewDocumentRequest]({ state, commit }) {
		commit(mutationTypes.SET_IS_DOCUMENT_FORM_SAVING, true);

		try {
			const response = await bodController.createChangeRequest(mapper.map(plainToInstance(Document, {
				...state.document,
				editableFile: state.editableFile,
				nonEditableFile: state.nonEditableFile,
				changeWarrantFileId: state.documentChangeWarrantFileMeta.id
			}), Document, ApiCreateDocumentRequest));

			alertService.addInfo(AlertKeys.CREATE_DOCUMENT_REQUEST_CREATED);

			await router.push({ name: RouteNames.REQUEST, params: { requestId: response.id } });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DOCUMENT_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateDocument]({ state, commit }) {
		commit(mutationTypes.SET_IS_DOCUMENT_FORM_SAVING, true);

		try {
			const { document: lastSaved } = snapshotMixin.stateSnapshotService.get(DocumentSnapshotKeys.DOCUMENT);
			const changes = getObjectsDiff(lastSaved, {
				...state.document,
				editableFile: state.editableFile,
				nonEditableFile: state.nonEditableFile
			});

			await bodController.updateDocument(state.document.id,
				mapper.map(changes, UpdateDocumentRequest, ApiUpdateDocumentRequest));

			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);

			await refreshRoute(router);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DOCUMENT_FORM_SAVING, false);
		}
	},
	async [actionTypes.createDocumentUnpublishRequest]({ commit, state }) {
		commit(mutationTypes.SET_IS_REQUEST_FORM_SAVING, true);

		try {
			const response = await bodController.unpublishChangeRequest(router.currentRoute.params.documentId,
				mapper.map(plainToInstance(ChangeRequest, { ...state.request }), ChangeRequest, ApiCreateDocumentUnpublishRequestRequest));

			alertService.addInfo(AlertKeys.UNPUBLISH_DOCUMENT_REQUEST_CREATED);
			await router.push({ name: RouteNames.REQUEST, params: { requestId: response.id } });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_REQUEST_FORM_SAVING, false);
		}
	},
	async [actionTypes.createDocumentRepublishRequest]({ commit, state }) {
		commit(mutationTypes.SET_IS_REQUEST_FORM_SAVING, true);
		try {
			
			const response = await bodController.republishChangeRequest(router.currentRoute.params.documentId,
				mapper.map(plainToInstance(ChangeRequest, { ...state.request }), ChangeRequest, ApiCreateDocumentRepublishRequestRequest));
			
			alertService.addInfo(AlertKeys.REPUBLISH_DOCUMENT_REQUEST_CREATED);
			await refreshRoute(router);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_REQUEST_FORM_SAVING, false);
		}
	},
	async [actionTypes.createRollbackDocumentRequest]({ commit, state }) {
		commit(mutationTypes.SET_IS_DOCUMENT_FORM_SAVING, true);

		try {
			const response = await bodController.rollbackChangeRequest(router.currentRoute.params.documentId,
				mapper.map(plainToInstance(ChangeRequest, { ...state.request }), ChangeRequest, ApiCreateRollbackDocumentRequestRequest));

			alertService.addInfo(AlertKeys.ROLLBACK_DOCUMENT_REQUEST_CREATED);
			await router.push({ name: RouteNames.REQUEST, params: { requestId: response.id } });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DOCUMENT_FORM_SAVING, false);
		}
	},
	async [actionTypes.createDocumentUpdateRequest]({ commit, state }) {
		commit(mutationTypes.SET_IS_DOCUMENT_FORM_SAVING, true);

		try {
			const response = await bodController.updateChangeRequest(router.currentRoute.params.documentId,
				mapper.map(plainToInstance(ChangeRequest, {
					...state.request,
					editableFile: state.requestEditableFile,
					nonEditableFile: state.requestNonEditableFile,
					changeWarrantFile: state.changeWarrantFileMeta
				}), ChangeRequest, ApiCreateDocumentChangeRequestRequest));

			alertService.addInfo(AlertKeys.UPDATE_DOCUMENT_REQUEST_CREATED);
			await router.push({ name: RouteNames.REQUEST, params: { requestId: response.id } });
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DOCUMENT_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateRequest]({ state, commit }) {
		commit(mutationTypes.SET_IS_REQUEST_FORM_SAVING, true);

		try {
			const { request: lastSaved } = snapshotMixin.stateSnapshotService.get(DocumentSnapshotKeys.REQUEST);
			const request = getObjectsDiff(lastSaved, {
				...state.request,
				editableFile: state.requestEditableFile,
				nonEditableFile: state.requestNonEditableFile
			});

			await bodController.updateRequest(router.currentRoute.params.requestId, mapper.map(request, UpdateRequest, ApiUpdateRequest));

			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);

			await refreshRoute(router);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_REQUEST_FORM_SAVING, false);
		}
	},
	async [actionTypes.fetchUsers]({ state, commit }) {
		if(state.users.length > 0)
			return;

		commit(mutationTypes.SET_IS_USERS_LOADING, true);

		try {
			const users = await bodController.getUsers();

			commit(mutationTypes.SET_USERS, users);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_USERS_LOADING, false);
		}
	},
	async [actionTypes.fetchDepartments]({ state, commit }) {
		if(state.departments.length > 0)
			return;

		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);

		try {
			const departments = await bodController.getDepartments();

			commit(mutationTypes.SET_DEPARTMENTS, departments);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	},
	async [actionTypes.fetchSections]({ state, commit }) {
		if(state.sections.length > 0)
			return;

		commit(mutationTypes.SET_IS_SECTIONS_LOADING, true);

		try {
			const sections = await bodController.getSections();

			commit(mutationTypes.SET_SECTIONS, sections);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_SECTIONS_LOADING, false);
		}
	},
	async [actionTypes.fetchFinanceSchemes]({ state, commit }) {
		if(state.isFinanceSchemesLoaded)
			return;

		commit(mutationTypes.SET_IS_FINANCE_SCHEMES_LOADING, true);

		try {
			const financeSchemes = await bodController.getFinanceSchemes();

			commit(mutationTypes.SET_FINANCE_SCHEMES, financeSchemes);

			commit(mutationTypes.SET_IS_FINANCE_SCHEMES_LOADED, true);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FINANCE_SCHEMES_LOADING, false);
		}
	},
	async [actionTypes.copyLink]({ state }) {
		try {
			const { link } = await bodController.getDocumentLink(state.document.id);

			if(!link) {
				alertService.addError(AlertKeys.COPY_LINK_ERROR);
				return;
			}

			navigator.clipboard.writeText(link);

			alertService.addInfo(AlertKeys.SUCCESS_COPIED_LINK);
		} catch (error) {
			console.error(error);
			alertService.addError(AlertKeys.COPY_LINK_ERROR);
		}
	},
	async [actionTypes.acceptRequest]({ commit }) {
		commit(mutationTypes.SET_IS_ACCEPTING, true);

		try {
			await bodController.acceptRequest(router.currentRoute.params.requestId);

			alertService.addInfo(AlertKeys.REQUEST_ACCEPTED);

			commit(mutationTypes.SET_IS_ACCEPT_BUTTONS_HIDDEN, true);

			await refreshRoute(router);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ACCEPTING, false);
		}
	},
	async [actionTypes.rejectRequest]({ commit, state }) {
		commit(mutationTypes.SET_IS_REJECTING, true);

		try {
			await bodController.declineRequest(router.currentRoute.params.requestId, state.declineReason);

			alertService.addInfo(AlertKeys.REQUEST_DECLINED);

			commit(mutationTypes.SET_IS_ACCEPT_BUTTONS_HIDDEN, true);

			await refreshRoute(router);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_REJECTING, false);
		}
	},
	async [actionTypes.handleAddDocumentFile]({ commit, state }, { isEditable, value }) {
		if(isEditable)
			commit(mutationTypes.SET_DOCUMENT_EDITABLE_FILE, mapper.map(value, ApiFileMeta, FileMeta));
		else
			commit(mutationTypes.SET_DOCUMENT_NON_EDITABLE_FILE, mapper.map(value, ApiFileMeta, FileMeta));
	},
	async [actionTypes.handleAddRequestFile]({ commit, state }, { isEditable, value }) {
		if(isEditable)
			commit(mutationTypes.SET_REQUEST_EDITABLE_FILE, mapper.map(value, ApiFileMeta, FileMeta));
		else
			commit(mutationTypes.SET_REQUEST_NON_EDITABLE_FILE, mapper.map(value, ApiFileMeta, FileMeta));
	},
	async [actionTypes.handleAddChangeWarrantFile]({ state, commit, dispatch }, file) {
		if(!file) {
			commit(mutationTypes.SET_CHANGE_WARRANT_FILE_META, new FileMeta());
			return;
		}

		try {
			commit(mutationTypes.SET_CHANGE_WARRANT_FILE_IS_LOADING, true);

			const { name, type } = file;
			let meta = await bodController.createTemperFile(new ApiCreateBodFile(file, name, type));

			commit(mutationTypes.SET_CHANGE_WARRANT_FILE_META, plainToInstance(FileMeta, meta));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_CHANGE_WARRANT_FILE_IS_LOADING, false);
		}
	},
	async [actionTypes.handleAddDocumentChangeWarrantFile]({ state, commit, dispatch }, file) {
		if(!file) {
			commit(mutationTypes.SET_DOCUMENT_CHANGE_WARRANT_FILE_META, new FileMeta());
			return;
		}

		try {
			commit(mutationTypes.SET_DOCUMENT_CHANGE_WARRANT_FILE_IS_LOADING, true);

			const { name, type } = file;
			let meta = await bodController.createTemperFile(new ApiCreateBodFile(file, name, type));

			commit(mutationTypes.SET_DOCUMENT_CHANGE_WARRANT_FILE_META, plainToInstance(FileMeta, meta));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_DOCUMENT_CHANGE_WARRANT_FILE_IS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<DocumentState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	},
	[mutationTypes.SET_SECTIONS](state, value) {
		state.sections = value;
	},
	[mutationTypes.SET_FINANCE_SCHEMES](state, value) {
		state.financeSchemes = value;
	},
	[mutationTypes.SET_USERS](state, value) {
		state.users = value;
	},
	[mutationTypes.SET_DOCUMENT_NAME](state, value) {
		state.document.name = value;
	},
	[mutationTypes.SET_DOCUMENT](state, value) {
		state.document = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_SECTION](state, value) {
		state.document.section = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_FINANCE_SCHEMES](state, value) {
		state.document.financeSchemes = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_RESPONSIBLE_DEPARTMENT](state, value) {
		state.document.responsibleDepartment = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_RESPONSIBLE_USER](state, value) {
		state.document.responsibleUser = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_EDITABLE_FILE](state, value) {
		state.editableFile = value;
	},
	[mutationTypes.SET_DOCUMENT_NON_EDITABLE_FILE](state, value) {
		state.nonEditableFile = value;
	},
	[mutationTypes.SET_DOCUMENT_LINKED_TO_FINANCE_SCHEMES](state, value) {
		state.document.linkedToFinanceSchemes = value;
	},
	[mutationTypes.SET_DOCUMENT_IS_WEB](state, value) {
		state.document.isWeb = value;
	},
	[mutationTypes.SET_DOCUMENT_WEB_LINK](state, value) {
		state.document.webLink = value;
	},
	[mutationTypes.SET_DOCUMENT_CHANGE_WARRANT](state, value) {
		state.document.changeWarrant = value;
	},
	[mutationTypes.SET_DOCUMENT_CHANGE_WARRANT_FILE_META](state, value) {
		state.documentChangeWarrantFileMeta = value;
	},
	[mutationTypes.SET_DOCUMENT_CHANGE_WARRANT_FILE_IS_LOADING](state, value) {
		state.documentChangeWarrantFileMeta.isLoading = value;
	},
	[mutationTypes.SET_CHANGE_WARRANTS](state, value) {
		state.changeWarrants = value;
	},
	[mutationTypes.SET_IS_USERS_LOADING](state, value) {
		state.isUsersLoading = value;
	},
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_IS_SECTIONS_LOADING](state, value) {
		state.isSectionsLoading = value;
	},
	[mutationTypes.SET_IS_FINANCE_SCHEMES_LOADING](state, value) {
		state.isFinanceSchemesLoading = value;
	},
	[mutationTypes.SET_REQUEST](state, value) {
		state.request = value;
	},
	[mutationTypes.SET_REQUEST_CHANGE_WARRANT](state, value) {
		state.request.changeWarrant = value;
	},
	[mutationTypes.SET_REQUEST_RESPONSIBLE_USER](state, value) {
		state.request.responsibleUser = cloneDeep(value);
	},
	[mutationTypes.SET_REQUEST_INITIATOR](state, value) {
		state.request.initiator = cloneDeep(value);
	},
	[mutationTypes.SET_REQUEST_DESCRIPTION](state, value) {
		state.request.description = value;
	},
	[mutationTypes.SET_REQUEST_EDITABLE_FILE](state, value) {
		state.requestEditableFile = value;
	},
	[mutationTypes.SET_REQUEST_NON_EDITABLE_FILE](state, value) {
		state.requestNonEditableFile = value;
	},
	[mutationTypes.SET_CHANGE_WARRANT_FILE](state, value) {
		state.changeWarrantFile = value;
	},
	[mutationTypes.SET_CHANGE_WARRANT_FILE_META](state, value) {
		state.changeWarrantFileMeta = value;
	},
	[mutationTypes.SET_REQUEST_STATUS](state, value) {
		state.request.status = value;
	},
	[mutationTypes.SET_REQUEST_WEB_LINK](state, value) {
		state.request.webLink = value;
	},
	[mutationTypes.SET_IS_ACCEPTING](state, value) {
		state.isAccepting = value;
	},
	[mutationTypes.SET_IS_REJECTING](state, value) {
		state.isRejecting = value;
	},
	[mutationTypes.SET_IS_ACCEPT_BUTTONS_HIDDEN](state, value) {
		state.isAcceptButtonsHidden = value;
	},
	[mutationTypes.SET_IS_DOCUMENT_FORM_SAVING](state, value) {
		state.isDocumentFormSaving = value;
	},
	[mutationTypes.SET_IS_REQUEST_FORM_SAVING](state, value) {
		state.isRequestFormSaving = value;
	},
	[mutationTypes.SET_DOCUMENT_PERMISSIONS](state, value) {
		state.documentPermissions = value;
	},
	[mutationTypes.SET_REQUEST_PERMISSIONS](state, value) {
		state.requestPermissions = value;
	},
	[mutationTypes.SET_IS_FINANCE_SCHEMES_LOADED](state, value) {
		state.isFinanceSchemesLoaded = value;
	},
	[mutationTypes.SET_DECLINE_REASON](state, value) {
		state.declineReason = value;
	},
	[mutationTypes.SET_SAVED_DOCUMENT_IS_WEB](state, value) {
		state.savedDocumentIsWeb = value;
	},
	[mutationTypes.SET_CHANGE_WARRANT_FILE_IS_LOADING](state, value) {
		state.changeWarrantFileMeta.isLoading = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const documentModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default documentModule;
